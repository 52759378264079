import React, { useRef, useState, useEffect } from "react";
import telegramIcon from "./assets/telegram-icon.png";
import twitterIcon from "./assets/x-icon.png";
import hahaCatGif from "./assets/haha-cat.gif";
import meowSound from "./assets/haha-cat-sound.mp3"; 
import "./App.css";

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const walletAddress = "64occtYWozpY1dEDMLbqCQ8uurJ5QG1CxoqiopK8pump";

  const playSoundAndGif = () => {
    if (audioRef.current) {
      audioRef.current.volume = 0.2; 
      audioRef.current.play();
    }
    setIsPlaying(true);
  };

  const stopSoundAndGif = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; 
    }
    setIsPlaying(false);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = 0.2; 

      audio.addEventListener("ended", stopSoundAndGif);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("ended", stopSoundAndGif);
      }
    };
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress).then(() => {
      alert("copied to clipboard");
    });
  };

  return (
    <div className="container">
      {/* GIF */}
      <img
        src={hahaCatGif}
        alt="HAHA Cat Gif"
        className={`gif ${isPlaying ? "playing" : ""}`}
        onMouseEnter={playSoundAndGif}
        onMouseLeave={stopSoundAndGif} 
      />

      {/* Audio Element */}
      <audio ref={audioRef} src={meowSound} preload="auto" />

      {/* Icons */}
      <div className="icons-container">
        <a href="https://t.me/hahacatsolana" target="_blank" rel="noopener noreferrer">
          <img src={telegramIcon} alt="Telegram" className="icon" />
        </a>
        <a href="https://x.com/hahacatsolana" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="X" className="icon" />
        </a>
      </div>

      {/* Texts */}
      <p className="text">$HAHA, you sold? Higher</p>
      <p className="subtext">we simply love the cat</p>
      <p className="ca">{walletAddress}</p>

      {/* Copy to Clipboard Button */}
      <button className="copy-button" onClick={copyToClipboard}>
        Copy Wallet Address
      </button>
    </div>
  );
}

export default App;
